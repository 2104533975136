@font-face {
    font-family: "Inter LoSnoCo";
    font-style: normal;
    font-weight: 100 900;
    font-display: swap;
    src: url("inter_losnoco.woff2") format("woff2");
}

@font-face {
    font-family: "Inter LoSnoCo";
    font-style: italic;
    font-weight: 100 900;
    font-display: swap;
    src: url("inter_losnoco_italic.woff2") format("woff2");
}

@import url('https://fonts.googleapis.com/css2?family=Recursive:slnt,wght,MONO@-15..0,300..1000,1&display=swap');

@import "../node_modules/bootstrap/scss/functions";


$primary: rgb(255, 115, 0);
$enable-dark-mode: true;
$color-mode-type: media-query;

@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/variables-dark";

$custom-colors: (
  "chris": rgb(63, 212, 176),
  "kevin": rgb(255, 157, 77)
);

$theme-colors: map-merge($theme-colors, $custom-colors);


@import "../node_modules/bootstrap/scss/maps";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/root";

@import "../node_modules/bootstrap/scss/utilities";

@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/containers";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/card";
@import "../node_modules/bootstrap/scss/alert";
@import "../node_modules/bootstrap/scss/list-group";

@import "../node_modules/bootstrap/scss/helpers";


@import "../node_modules/bootstrap/scss/utilities/api";

//@import "../node_modules/bootstrap/scss/bootstrap";

html,
body {
    font-family: 'Inter LoSnoCo', "Inter", sans-serif;
    hyphens: auto;
    //letter-spacing: -0.025em;
}

h1 {
    font-weight: 1000;
}
h2 {
    font-weight: 800;
}
h3 {
    font-weight: 600;
}
h4 {
    font-weight: 400;
}



em {
    font-variation-settings: "slnt" -15;
}

.headline {
    font-size: 16pt;
}

.casual {
    font-variation-settings: "CASL" 1 !important;
}


code,
pre {
    font-family: 'Recursive', monospace;
    font-variation-settings: "MONO" 1;
}

img {
    @extend .img-fluid, .mx-auto, .d-block;
}

.drop-shadow {
    filter: drop-shadow(0px 5px 5px #00000089);
}
